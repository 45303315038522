import { useState, useContext } from 'react'
import { Link } from 'react-router-dom';
import LogoGris from '../assets/img/LogoGobMich-Artboard1.png'
import IconUI from '../components/iu/icon_ui'
import { BiLogOutCircle } from 'react-icons/bi'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { MdKeyboardArrowDown } from 'react-icons/md'
import { FaHome, FaWpforms, FaRegBuilding, FaFolderOpen, FaFileSignature, FaHandsHelping, FaHospitalUser } from 'react-icons/fa';
import { FaPeopleGroup, FaTreeCity, FaUnlockKeyhole } from "react-icons/fa6";
import { TbClipboardText } from "react-icons/tb";
import { GiGraduateCap } from "react-icons/gi";
import { BsBuildings, BsFileBinary } from "react-icons/bs";
import { PiSignatureBold } from "react-icons/pi";
import OverLayIU from './iu/overlay_ui';
import { useNavigate } from "react-router-dom";
import { GlobalContext } from '../context/global_context';
import Grid from '@mui/material/Grid';
import { Box, Modal, TextField, InputAdornment, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { NotificacionContext } from '../context/notificacion_context';
import { services } from '../services/api';
import { styled } from '@mui/material/styles';
import LoaderComponent from '../components/admin/LoaderComponent';
import Button from '@mui/material/Button';





export function Menu({ onClose }) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        boxShadow: 24,
        //border: '2px solid #661d40',
        border: '2px solid #661d40',
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "auto",
        zIndex: 100,
        borderRadius: "40px",
    };
    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    const [loader, setLoader] = useState(false);

    const { showNotification } = useContext(NotificacionContext)

    const { setFiltro, setFiltro2, setAcuse, setPeticion, setSearchText,setDashboard,setFiltroLeida,setFiltroUser } = useContext(GlobalContext)

    const goTo = useNavigate();
    const rol = localStorage.getItem('rol');
    const firmante = localStorage.getItem('FIRMAR_DOCUMENTOS');
    const revisor = localStorage.getItem('REVISAR_DOCUMENTOS');
    const audiencia = localStorage.getItem('AUDIENCIA');
    const lectura = localStorage.getItem('SOLO_LECTURA');

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword })
    }

    const handlePass = (e) => {
        setState({ ...state, password: e.target.value })
    }
    const [state, setState] = useState({
        toggleCss: false,
        toggleForms: false,
        toggleComponents: false,
        password: '',
        openPass: false,
    })

    const openModal = () => {
        setState({ ...state, openPass: true });
    }

    const handleClose = () => {
        setState({ ...state, openPass: false });
    }


    const editarPassword = async () => {
        if (state.password === "") {
            showNotification('error', 'Ups...', 'Favor de llenar los campos', null, 4);
        } else {
            setLoader(true)
            const cambiarPass = await services({
                method: 'POST', service: `usuarios/editarPassword`, body: {
                    password: state.password
                }
            })
            if (cambiarPass.status === 200) {
                setLoader(false);
                setState({ ...state, password: '', openPass: false });
                showNotification('success', 'Contraseña', 'Se ha cambiado con éxito', null, 4);

            } else {
                setLoader(false)
                showNotification('error', 'Ups...', 'Error al cambiar la contraseña', null, 4);
            }
        }
    }

    return (
        <>



            <OverLayIU type={"transparent"} onClick={() => { onClose() }}>
            </OverLayIU>
            <div>
                {loader &&
                    <LoaderComponent />
                }
                {rol === 'ADMINISTRADOR' ?
                    <div className="menu h-100 menuIzquierdo contenedorModalUrs">
                        <div className="brand  flex-column">
                            <img className="justify-content-start w-100" alt='logoGris' src={LogoGris} />
                        </div>

                        <Link to={`/inicio`}>
                            <div className="menu_item" onClick={() => {
                                setFiltro('');
                                setFiltro2('');
                                setAcuse('');
                                setPeticion('');
                                setSearchText('');
                                onClose();

                            }}>
                                <div className='p-2'>
                                    <IconUI>
                                        <FaHome />
                                    </IconUI>
                                </div>
                                <div className='text-small'>
                                    INICIO
                                </div>
                            </div>
                        </Link>

                        <div className="menu_item">
                            <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                setFiltro('');
                                setFiltro2('');
                                setAcuse('');
                                setPeticion('');
                                setSearchText('');
                                goTo("/firmas")
                                onClose()
                            }}>
                                <div className='p-2'>
                                    <IconUI>
                                        < PiSignatureBold />
                                    </IconUI>
                                </div>
                                <div className='text-small'>
                                    FIRMAS
                                </div>
                            </div>
                        </div>

                        <div className="menu_item">
                            <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                setFiltro('');
                                setFiltro2('');
                                setAcuse('');
                                setPeticion('');
                                setSearchText('');
                                goTo("/usuarios")
                                onClose()
                            }}>
                                <div className='p-2'>
                                    <IconUI>
                                        <AccountCircleIcon />
                                    </IconUI>
                                </div>
                                <div className='text-small'>
                                    USUARIOS
                                </div>
                            </div>
                        </div>

                        <div className="menu_item">
                            <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                setFiltro('');
                                setAcuse('');
                                setFiltro2('');
                                setPeticion('');
                                setSearchText('');
                                goTo("/ciudadanos")
                                onClose()
                            }}>
                                <div className='p-2'>
                                    <IconUI>
                                        < FaHospitalUser />
                                    </IconUI>
                                </div>
                                <div className='text-small'>
                                    CIUDADANOS
                                </div>
                            </div>
                        </div>

                        <div className="menu_item">
                            <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                setFiltro('');
                                setAcuse('');
                                setFiltro2('');
                                setPeticion('');
                                setSearchText('');
                                goTo("/solicitudes")
                                onClose()
                            }}>
                                <div className='p-2'>
                                    <IconUI>
                                        <FaFileSignature />
                                    </IconUI>
                                </div>
                                <div className='text-small'>
                                    SOLICITUDES
                                </div>
                            </div>
                        </div>

                        <div className="menu_item">
                            <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                setFiltro('');
                                setFiltro2('');
                                setAcuse('');
                                setPeticion('');
                                setSearchText('');
                                setFiltroLeida('');
                                setDashboard(0)
                                goTo("/peticiones")
                                onClose()
                            }}>
                                <div className='p-2'>
                                    <IconUI>
                                        <FaHandsHelping />
                                    </IconUI>
                                </div>
                                <div className='text-small'>
                                    PETICIONES
                                </div>
                            </div>
                        </div>

                        <div className="menu_item">
                            <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                setFiltro('');
                                setFiltro2('');
                                setAcuse('');
                                setPeticion('');
                                setSearchText('');
                                goTo("/folios")
                                onClose()
                            }}>
                                <div className='p-2'>
                                    <IconUI>
                                        <BsFileBinary />
                                    </IconUI>
                                </div>
                                <div className='text-small'>
                                    FOLIOS
                                </div>
                            </div>
                        </div>

                        <div className="menu_item">
                            <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                setFiltro('');
                                setFiltro2('');
                                setAcuse('');
                                setPeticion('');
                                setSearchText('');
                                goTo("/audiencia")
                                onClose()
                            }}>
                                <div className='p-2'>
                                    <IconUI>
                                        <FaPeopleGroup />
                                    </IconUI>
                                </div>
                                <div className='text-small'>
                                    AUDIENCIA
                                </div>
                            </div>
                        </div>

                        <div className="menu_item" onClick={() => { setState({ ...state, toggleForms: !state.toggleForms }) }}>
                            <div className='w-100 d-flex flex-row justify-content-center align-items-center'>
                                <div className='p-2'>
                                    <IconUI>
                                        <FaWpforms />
                                    </IconUI>
                                </div>
                                <div className='text-small'>
                                    CATÁLOGOS
                                </div>
                                <div className="col"></div>
                                <div onClick={() => { setState({ ...state, toggleForms: !state.toggleForms }) }} className='toggle'>
                                    <MdKeyboardArrowDown />
                                </div>
                            </div>
                        </div>

                        {state.toggleForms ?
                            <div className='ps-4 border-top'>
                                <div className="menu_item" >
                                    <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                        setFiltro('');
                                        setFiltro2('');
                                        setAcuse('');
                                        setPeticion('');
                                        setSearchText('');
                                        goTo("/dependencias")
                                        onClose()
                                    }}>
                                        <div className='p-2'>
                                            <IconUI>
                                                <FaRegBuilding />
                                            </IconUI>
                                        </div>
                                        <div className='text-small'>
                                            DEPENDENCIAS
                                        </div>

                                    </div>
                                </div>
                                <div className='menu_item' onClick={() => {
                                    setFiltro('');
                                    setFiltro2('');
                                    setAcuse('');
                                    setPeticion('');
                                    setSearchText('');
                                    goTo("/tiposCargo")
                                    onClose()
                                }}>
                                    <div className='p-2'>
                                        <BsBuildings />
                                    </div>
                                    <div className='text-small'>
                                        TIPOS DE CARGO
                                    </div>
                                </div>
                                <div className='menu_item' onClick={() => {
                                    setFiltro('');
                                    setFiltro2('');
                                    setAcuse('');
                                    setPeticion('');
                                    setSearchText('');
                                    goTo("/titulos")
                                    onClose()
                                }}>
                                    <div className='p-2'>
                                        <GiGraduateCap />
                                    </div>
                                    <div className='text-small'>
                                        TÍTULOS
                                    </div>
                                </div>
                                <div className='menu_item' onClick={() => {
                                    setFiltro('');
                                    setFiltro2('');
                                    setAcuse('');
                                    setPeticion('');
                                    setSearchText('');
                                    goTo("/localidades")
                                    onClose()
                                }}>
                                    <div className='p-2'>
                                        <FaTreeCity />
                                    </div>
                                    <div className='text-small'>
                                        LOCALIDADES
                                    </div>
                                </div>
                                <div className='menu_item' onClick={() => {
                                    setFiltro('');
                                    setFiltro2('');
                                    setAcuse('');
                                    setPeticion('');
                                    setSearchText('');
                                    goTo("/fuentesSolicitud")
                                    onClose()
                                }}>
                                    <div className='p-2'>
                                        <TbClipboardText />
                                    </div>
                                    <div className='text-small'>
                                        FUENTES DE SOLICITUD
                                    </div>
                                </div>
                                <div className='menu_item' onClick={() => {
                                    setFiltro('');
                                    setFiltro2('');
                                    setAcuse('');
                                    setPeticion('');
                                    setSearchText('');
                                    goTo("/tiposPeticion")
                                    onClose()
                                }}>
                                    <div className='p-2'>
                                        <FaFolderOpen />
                                    </div>
                                    <div className='text-small'>
                                        TIPOS DE PETICIÓN
                                    </div>
                                </div>

                            </div>
                            :
                            null
                        }

                        <div className='col'></div>
                        <div className="menu_item">
                            <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                setFiltro('');
                                setFiltro2('');
                                setPeticion('');
                                setSearchText('');
                                setAcuse('');
                                openModal();
                            }}>
                                <div className='p-2'>
                                    <IconUI>
                                        <FaUnlockKeyhole />
                                    </IconUI>
                                </div>
                                <div className='text-small'>
                                    CAMBIAR CONTRASEÑA
                                </div>
                            </div>
                        </div>
                        <div className='mb-3 d-flex justify-content-center'>
                            <div className='icon_btn' onClick={() => {
                                localStorage.clear();
                                onClose();
                                goTo("..", { replace: "/login" });
                                setSearchText('');


                            }}>
                                <IconUI size={30} >
                                    <BiLogOutCircle />
                                </IconUI>
                                <div className='icon_btn_help' >Cerrar Sesión</div>
                            </div>
                        </div>
                    </div>
                    :
                    rol === 'USUARIO' ?
                        <div className="menu h-100 menuIzquierdo contenedorModalUrs">
                            <div className="brand  flex-column">
                                <img className="justify-content-start w-100" alt='logoGris' src={LogoGris} />
                            </div>

                            {lectura ?
                                <Link to={`/inicioUser`}>
                                    <div className="menu_item" onClick={() => {
                                        setFiltro('');
                                        setFiltro2('');
                                        setAcuse('');
                                        setPeticion('');
                                        setSearchText('');
                                        onClose();

                                    }}>
                                        <div className='p-2'>
                                            <IconUI>
                                                <FaHome />
                                            </IconUI>
                                        </div>
                                        <div className='text-small'>
                                            INICIO
                                        </div>
                                    </div>
                                </Link>
                                :
                                <></>
                            }

                            {
                                firmante || revisor ?
                                    <div className="menu_item">
                                        <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                            setFiltro('');
                                            setFiltro2('');
                                            setAcuse('');
                                            setPeticion('');
                                            setSearchText('');
                                            goTo("/firmaUser")
                                            onClose()
                                        }}>
                                            <div className='p-2'>
                                                <IconUI>
                                                    < PiSignatureBold />
                                                </IconUI>
                                            </div>
                                            <div className='text-small'>
                                                FIRMAS
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }

                            {
                                lectura ?
                                    <>
                                        <div className="menu_item">
                                            <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                                setFiltro('');
                                                setAcuse('');
                                                setFiltro2('');
                                                setPeticion('');
                                                setSearchText('');
                                                goTo("/ciudadanosUsuario")
                                                onClose()
                                            }}>
                                                <div className='p-2'>
                                                    <IconUI>
                                                        < FaHospitalUser />
                                                    </IconUI>
                                                </div>
                                                <div className='text-small'>
                                                    CIUDADANOS
                                                </div>
                                            </div>
                                        </div>

                                        <div className="menu_item">
                                            <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                                setFiltro('');
                                                setAcuse('');
                                                setFiltro2('');
                                                setPeticion('');
                                                setSearchText('');
                                                goTo("/solicitudesUsuario")
                                                onClose()
                                            }}>
                                                <div className='p-2'>
                                                    <IconUI>
                                                        <FaFileSignature />
                                                    </IconUI>
                                                </div>
                                                <div className='text-small'>
                                                    SOLICITUDES
                                                </div>
                                            </div>
                                        </div>

                                        <div className="menu_item">
                                            <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                                setFiltro('');
                                                setFiltro2('');
                                                setAcuse('');
                                                setPeticion('');
                                                setSearchText('');
                                                setFiltroLeida('');
                                                setFiltroUser('')
                                                setDashboard(0)
                                                goTo("/peticionesUsuario")
                                                onClose()
                                            }}>
                                                <div className='p-2'>
                                                    <IconUI>
                                                        <FaHandsHelping />
                                                    </IconUI>
                                                </div>
                                                <div className='text-small'>
                                                    PETICIONES
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :

                                    <></>

                            }


                            {
                                audiencia ?
                                    <div className="menu_item">
                                        <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                            setFiltro('');
                                            setFiltro2('');
                                            setAcuse('');
                                            setPeticion('');
                                            setSearchText('');
                                            goTo("/audienciaUser")
                                            onClose()
                                        }}>
                                            <div className='p-2'>
                                                <IconUI>
                                                    <FaPeopleGroup />
                                                </IconUI>
                                            </div>
                                            <div className='text-small'>
                                                AUDIENCIA
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }


                            <div className='col'></div>
                            <div className="menu_item">
                                <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                    setFiltro('');
                                    setFiltro2('');
                                    setAcuse('');
                                    setPeticion('');
                                    setSearchText('');
                                    openModal();
                                }}>
                                    <div className='p-2'>
                                        <IconUI>
                                            <FaUnlockKeyhole />
                                        </IconUI>
                                    </div>
                                    <div className='text-small'>
                                        CAMBIAR CONTRASEÑA
                                    </div>
                                </div>
                            </div>
                            <div className='mb-3 d-flex justify-content-center'>
                                <div className='icon_btn' onClick={() => {
                                    localStorage.clear();
                                    onClose();
                                    goTo("..", { replace: "/login" });
                                    setSearchText('');

                                }}>
                                    <IconUI size={30} >
                                        <BiLogOutCircle />
                                    </IconUI>
                                    <div className='icon_btn_help' >Cerrar Sesión</div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="menu h-100 menuIzquierdo contenedorModalUrs">
                            <div className="brand  flex-column">
                                <img className="justify-content-start w-100" alt='logoGris' src={LogoGris} />
                            </div>

                            <Link to={`/inicioDep`}>
                                <div className="menu_item" onClick={() => {
                                    setFiltro('');
                                    setFiltro2('');
                                    setAcuse('');
                                    setPeticion('');
                                    setSearchText('');
                                    onClose();

                                }}>
                                    <div className='p-2'>
                                        <IconUI>
                                            <FaHome />
                                        </IconUI>
                                    </div>
                                    <div className='text-small'>
                                        INICIO
                                    </div>
                                </div>
                            </Link>

                            <div className="menu_item">
                                <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                     setFiltro('');
                                     setFiltro2('');
                                     setAcuse('');
                                     setPeticion('');
                                     setSearchText('');
                                     setFiltroLeida('');
                                     setDashboard(0)
                                    goTo("/peticionesDep")
                                    onClose()
                                }}>
                                    <div className='p-2'>
                                        <IconUI>
                                            <FaHandsHelping />
                                        </IconUI>
                                    </div>
                                    <div className='text-small'>
                                        PETICIONES
                                    </div>
                                </div>
                            </div>

                            <div className='col'></div>
                            <div className="menu_item">
                                <div className='w-100 d-flex flex-row justify-content-start align-items-center' onClick={() => {
                                    setFiltro('');
                                    setFiltro2('');
                                    setAcuse('');
                                    setPeticion('');
                                    setSearchText('');
                                    openModal();
                                }}>
                                    <div className='p-2'>
                                        <IconUI>
                                            <FaUnlockKeyhole />
                                        </IconUI>
                                    </div>
                                    <div className='text-small'>
                                        CAMBIAR CONTRASEÑA
                                    </div>
                                </div>
                            </div>
                            <div className='mb-3 d-flex justify-content-center'>
                                <div className='icon_btn' onClick={() => {
                                    localStorage.clear();
                                    setSearchText('')
                                    onClose();
                                    goTo("..", { replace: "/login" });
                                    // window.location.reload();

                                }}>
                                    <IconUI size={30} >
                                        <BiLogOutCircle />
                                    </IconUI>
                                    <div className='icon_btn_help' >Cerrar Sesión</div>
                                </div>
                            </div>
                        </div>
                }
                {/*Modal para crear */}
                <Modal
                    style={{ zIndex: 100 }}
                    open={state.openPass}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            CAMBIAR CONTRASEÑA
                        </h3>

                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    size='small'
                                    label="CONTRASEÑA"
                                    variant="outlined"
                                    type={state.showPassword ? 'text' : 'password'}
                                    value={state.password}
                                    onChange={handlePass}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        sx: { borderRadius: 30 }
                                    }}
                                />
                            </Grid>
                            <Grid marginLeft="auto" item >
                                <ColorButton  onClick={() => {
                                    editarPassword();

                                }}

                                >
                                    Editar
                                </ColorButton>
                            </Grid>
                        </Grid>




                    </Box>
                </Modal>
            </div>

        </>
    )
}
